import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_ACCOUNTS } from "@/core/services/graphql/queries/dynamics365/account";
import * as pbi from "powerbi-client";
import dayjs from "dayjs";
import { GET_POWER_BI_REPORTS } from "@/core/services/graphql/queries/power_bi/report";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  data() {
    return {
      sentiaProviderOptions: [
        { display: "Sentia BE", value: "Sentia BE" },
        { display: "Sentia NL", value: "Sentia NL" }
      ],
      sentiaProviderSelectedItem: "",
      activeCustomers: true,
      companyNameSearchInput: "",
      allowedOptions: [{ display: "Full Report", value: "full_report" }],
      selectedCustomer: {},
      skipInvoiceQuery: true,
      isVisible: false,
      hasEmbedUrl: false
    };
  },
  apollo: {
    accounts: {
      query: () => GET_ACCOUNTS,
      variables() {
        return {
          filters: {
            active: { value: this.activeCustomers },
            sen_provider: { value: this.sentiaProviderSelectedItem }
          }
        };
      },
      update(data) {
        return data.accounts;
      },
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    },
    invoices: {
      client: "connectClient",
      query: () => GET_POWER_BI_REPORTS,
      variables() {
        return {
          filters: {
            accountNumber: {
              value: [this.selectedCustomer.accountnumber]
            },
            reportName: { value: ["INVOICES"] }
          }
        };
      },
      update(data) {
        this.skipInvoiceQuery = true;
        const result = data.reports.powerBI.result;
        if (result) {
          this.hasEmbedUrl = true;
          this.renderPowerBIReport(result.embedUrl, result.embedToken);
        }
        return result;
      },
      skip() {
        return this.skipInvoiceQuery;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  methods: {
    filterData(item, queryText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.accountnumber.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    reloadAccounts() {
      if (this.sentiaProviderSelectedItem) {
        this.$apollo.queries.accounts.skip = false;
        this.$apollo.queries.accounts.refetch();
      }
    },
    loadData() {
      if (this.selectedCustomer) {
        this.skipInvoiceQuery = false;
        this.$apollo.queries.invoices.refetch();
      }
    },
    renderPowerBIReport(embedUrl, embedToken) {
      const config = {
        tokenType: pbi.models.TokenType.Embed,
        type: "report",
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true
        },
        embedUrl: embedUrl,
        accessToken: embedToken
      };
      const overviewfilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "sg_int_CB_2 Customers",
          column: "Code"
        },
        operator: "In",
        values: [this.selectedCustomer.accountnumber],
        filterType: 1
      };

      const pivotFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "sg_int_CB_2 Customers",
          column: "ClusterPath1"
        },
        operator: "In",
        values: [this.sentiaProviderSelectedItem],
        filterType: 1
      };

      const reportContainer = document.getElementById("reportContainer");
      const powerBI = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      powerBI.reset(reportContainer);
      const report = powerBI.embed(reportContainer, config);
      report.on("loaded", async function () {
        const pages = await report.getPages();
        for (const page of pages) {
          const filters = await page.getFilters();
          filters.forEach(filter => {
            if (filter.$schema === "http://powerbi.com/product/schema#basic") {
              if (
                filter.target.table === "sg_int_CB_2 Customers" &&
                filter.target.column === "Code"
              ) {
                page.updateFilters(1, [overviewfilter]);
              } else if (
                filter.target.table === "sg_int_CB_2 Customers" &&
                filter.target.column === "ClusterPath1"
              ) {
                page.updateFilters(1, [pivotFilter]);
              }
            }
          });
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "My Insights"
      }
    ]);
  }
};
